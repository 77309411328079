import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import "./CardComponent.css"

const IndexPage = props => {
	const filteredPages = props.data.allContentfulStore.edges.filter(
		page =>
			page.relativeUrl !== '/dev-404-page/' &&
			page.relativeUrl !== '/offline-plugin-app-shell-fallback/' &&
			page.relativeUrl !== '/404/' &&
			page.relativeUrl !== '/' &&
			page.relativeUrl !== '/404.html' &&
			page.relativeUrl !== '/privacy-policy/' &&
			page.relativeUrl !== '/terms/'
	)
	const sortedPages = filteredPages.sort()
	return (
		<Layout>
			<div className="container">
			{sortedPages.length > 0 && (
						<div className='pt-5'>
							<h3 className='title is-4 has-text-centered'>We are a number of Maytronics dealers throughout the country, find your local dealer below:</h3>
							<div className='columns is-multiline'>
							{sortedPages.map((page) => (
         						 <div key={page} className="column is-4">
										<Link to={page.node.relativeUrl}>
           						 			<div className="card custom-card">
											<div className="card-content custom-card-content">
												<div className="media">
													<div className="media-left">
														<figure className="image is-96x96">
															<img src={page.node.storeLogo?.file.url} />
														</figure>
													</div>
													<div className="media-content">
														<p className="title is-4">{page.node.shopName}</p>
													<div className="content">{page.node.shopEmail}</div>
													<a href={page.node.relativeUrl} className="is-underlined">{page.node.relativeUrl.split("/")[0].toUpperCase()}</a>
													</div>
												</div>
											</div>

											<div className="card-image">
												<figure className="image is-4by3">
												<img src={page.node.staticMapUrl.staticMapUrl} />
											</figure>
											</div>

           						 			</div>
										</Link>
          						</div>
        						))}
							</div>
						</div>
					)}
			</div>
		</Layout>
	)
}

IndexPage.propTypes = {
	data: PropTypes.any,
}

export default IndexPage

export const PageListQuery = graphql`
	query pageListQuery {
		allContentfulStore(filter: {storeCountry: {eq: "ZA"}}) {
			edges {
				node {
					shopName
					shopEmail
					relativeUrl
					storeLogo {
						id
						file {
							url
						}
					}
					staticMapUrl {
						staticMapUrl
					}
				}
			}
		}
	}
`
